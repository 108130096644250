import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { replaceCompanyName } from '../constants/config';
import Layout from '../components/layout/layout';
import { navigate, withPrefix } from 'gatsby';

const RedirectIndex = ({ data }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const langKey = 'en';
      const homeUrl = withPrefix(`/${langKey}/`);
      navigate(homeUrl);
    }
  }, []);

  return (
    <>
      <Layout
        meta={{
          ...replaceCompanyName(data.homeJson, 'seo').seo,
          canonical: '',
        }}
      ></Layout>
    </>
  );
};

RedirectIndex.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query IndexQuery {
    homeJson {
      seo {
        pageTitle
        pageDescription
      }
    }
  }
`;

export default RedirectIndex;
